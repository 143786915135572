import Image from 'next/legacy/image';
import React from 'react';
import Collapse from '../components/Collapse';
import { FAQ_SECTION } from '../sectionNames';
import useUpdateUrlHash from '../hooks/useUpdateUrlHash';
import withLandingPageSection from '~/components/common/HOC/withLandingPageSection';

interface FAQProps {
  inView?: boolean;
}

const FAQ: React.FC<FAQProps> = ({ inView }) => {
  useUpdateUrlHash(inView, FAQ_SECTION);

  const questions = [
    {
      title: 'What is Internet Game and how does it work?',
      content:
        'Internet Game is a community-driven gaming platform that empowers anyone to create a community, send invites & play games together. The platform is browser-based allowing for seamless access. Groups can string together a series of games from our ever-growing game library.',
    },
    {
      title: 'What devices are supported?',
      content: `Internet Game is optimized for desktop browsers, with Chrome and Firefox being the recommended choices for the best performance. You can enjoy the game on any modern laptop or desktop computer. 
        Mobile devices and tablets are not currently supported.`,
    },
    {
      title: 'Are there any other technical requirements?',
      content: 'Nope! All you need is a stable internet connection and a computer.',
    },
    {
      title: 'Can anyone join my community?',
      content:
        'You’re in control of who can play. Gate your community using emails, direct invites, or by sharing a link. Invite as many players as you’d like. The more, the merrier!',
    },
    {
      title: 'How many players can play each game?',
      content:
        'Whether you’re a group of 10 or 1000, our platform scales to fit your needs. Each game on our platform has different player maximums, but don’t fear! If you have a group that exceeds the capacity of a game, the platform will seamlessly split you up into different games and then bring everyone back to the lobby for the next round.',
    },
    {
      title: 'Can Internet Game be used internationally?',
      content:
        'Yep! From Tennessee to Timbuktu, as long as you have a decent wifi connection, you can play.',
    },
    {
      title: 'How often can I use Internet Game a month?',
      content: 'As much as you’d like! There’s no limit to how much you can play.',
    },
    {
      title: 'What types of games are available on your platform?',
      content: `Internet Game has an expansive variety of game types to ensure that there’s something for everyone. Whether you’re looking for social icebreakers, cooperative strategy games, or fast-paced arcade showdowns, we’ve got you covered.
        Plus, all of our games are hyper simple to play (just arrow keys and space bar for controls), easy to understand, and — most importantly — really fun 🙂
        P.S. We’re always launching more games to keep your Game Nights fresh!`,
    },
    {
      title: 'What is the time commitment of games?',
      content: 'We leave that up to you! All of our games take on average 3-5 minutes.',
    },
    /*
    {
      title: 'How is this different from other virtual team-building solutions?',
      content:
        'No cheesy hosts, no boring “games”, no scheduling required, no player maximums, and no sticker shock (we cost ~10x less than other services). Just easy-breezy, team-building fun.',
    },
    {
      title: 'Once I sign up, am I locked in on a contract?',
      content:
        'Nope! We offer a monthly subscription and you can cancel anytime. Our product & pricing is designed to be a “no-brainer” decision for you.',
    },
    {
      title: 'Can we try the platform before purchasing a subscription?',
      content:
        'Of course! We offer a free trial period for you to test Internet Game and see if it’s right for you. If your team is made up of less than 6 members, it’s always free!',
    },
     */
    {
      title: 'Does it cost anything?',
      content: `Internet Game is 100% free to play in public rooms. Private communities are free for up to 5 players, and $2/user/month if you have more than 5.`,
    },
    {
      title: 'What if I have questions or issues?',
      content:
        'Having trouble? Click the chat icon in the bottom-right corner of your screen and we’ll get back to you ASAP.',
    },
  ];

  return (
    <div
      id={FAQ_SECTION}
      className='relative flex flex-col items-center justify-center z-10 scroll-mt-28 lg:scroll-mt-52'
    >
      {/* Shapes */}
      <div className='absolute h-[70rem] w-full top-0 left-0 hidden lg:inline pointer-events-none'>
        <div className='absolute h-14 w-12 top-0 right-32'>
          <Image layout='fill' src='/assets/landingB2BAug23/shapes/pie_4.svg' alt='pie' />
        </div>
        <div className='absolute h-20 w-20 top-[32.75%] left-16'>
          <Image src='/assets/landingB2BAug23/shapes/pixels_2.svg' alt='pixels 2' layout='fill' />
        </div>
        <div className='absolute h-14 w-14 bottom-[13.25rem] right-28'>
          <Image src='/assets/landingB2BAug23/shapes/pixels_1.svg' alt='pixels 1' layout='fill' />
        </div>
        <div className='absolute h-12 w-12 -bottom-[6.75rem] left-[10.125rem]'>
          <Image
            src='/assets/landingB2BAug23/shapes/outline_circle.svg'
            alt='pixels 1'
            layout='fill'
          />
        </div>
      </div>

      {/* Heading */}
      <div className='relative mt-[4rem] md:mt-[7rem] lg:mt-5 pointer-events-none'>
        <div className='absolute h-full w-full top:0 left-0 hidden lg:inline'>
          <div className='absolute h-9 w-9 -top-[1rem] -left-[10.5rem]'>
            <Image layout='fill' src='/assets/landingB2BAug23/faq/thinking.svg' alt='thinking' />
          </div>

          <div className='absolute h-9 w-9 -right-[10.65rem] bottom-[1rem]'>
            <Image layout='fill' src='/assets/landingB2BAug23/faq/monocle.svg' alt='monocle' />
          </div>
        </div>
        <div className='relative flex items-start justify-center lg:pb-10'>
          <div className='text-white uppercase font-bold leading-[100%] space-grotesk-bold text-center text-[2.5rem] md:text-[3rem] lg:text-[6rem] [text-shadow:_0px_27px_49.5px_rgb(10_13_36_/_30%),_0px_4.5px_15px_rgb(10_13_36_20%)]'>
            FAQ
          </div>
        </div>
      </div>

      {/* Questions */}
      <div className='mb-16 mt-[1.75rem] max-w-2xl md:max-w-none lg:min-h-[70rem] lg:-ml-[0.875rem] lg:-mt-[1rem] px-5 lg:px-0'>
        {questions.map(({ title, content }) => (
          <Collapse key={title} title={title} content={content} />
        ))}

        {/* added this div to attempt to address spacing differences between desktop and mobile */}
        {/* <div className='hidden lg:inline'>
          <Collapse
            title='Can I take Game Night for a spin before buying?'
            content='Laborum est aliqua nostrud irure tempor minim labore veniam sunt tempor laborum.Eiusmod dolor do Lorem est dolore quis sit ullamco nulla fugiat.'
          />
          <Collapse
            title='How do I become an admin and make a community?'
            content='Dolor dolore ipsum ad labore voluptate laboris proident veniam pariatur amet anim magna.Aliquip cillum irure tempor cupidatat ad mollit qui officia commodo est.'
          />
          <Collapse
            title='Got questions or need help?'
            content='Officia id consequat commodo excepteur velit excepteur reprehenderit dolor.Sint cillum deserunt in et nisi.'
          />
        </div> */}
      </div>
    </div>
  );
};

export default withLandingPageSection(FAQ);
